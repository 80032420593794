import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    document.getElementById("btn-show-categories").addEventListener("click", () => {
      document.querySelector("#items-list").classList.toggle("row-cols-lg-3");
      document.querySelector("#items-list").classList.toggle("active");
      document.querySelector("#product-listing-page .container-fluid").classList.toggle("opened");
    });
  }

  disconnect() {}
}
