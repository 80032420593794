import { Controller } from "@hotwired/stimulus"

export default class extends Controller{
  connect(){
    var quoteTrigger = document.querySelector('#quoteTrigger')
    var quickAddButtons = document.querySelectorAll('.quick-add-button')

    quickAddButtons.forEach(function(element){
      var quickAddForm = element.closest('form')

      quickAddForm.addEventListener('turbo:submit-end', (e) => {
        quoteTrigger.click();
      });
    })

  }

}
