import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect(){
    let search = document.getElementById('searchForm');
    search.addEventListener('shown.bs.collapse', focusSearch);

    function focusSearch() {
      let searchInput = document.getElementById('nav-search');
      searchInput.focus();
    }
    
  }
}
