import { Controller } from "@hotwired/stimulus"

export default class extends Controller{

  connect(){
    var quoteTrigger = document.querySelector('#quoteTrigger'),
    quoteForm = document.querySelector('form.quote'),
    quoteInput = Array.from(document.querySelectorAll('.quantity-select'));

    quoteForm.addEventListener('turbo:submit-end', (e) => {
      quoteTrigger.click();
      if (e.detail.success) {
        quoteInput.forEach(function(input, index){
          index == 0 ? input.value = "" : input.value = 0;
        })
      }
    });
  }

}
