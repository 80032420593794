import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["quoteTotal", "quoteItemCount", "quoteLineItem"]
  static values = { total: String };
  connect(){}
  updateQuotePrice(){
    let pickupDate = document.getElementById('quote_pickup_date'),
    returnDate = document.getElementById('quote_return_date'),
    quoteItemCopy = document.getElementById('quoteSummary'),
    quoteItemCount =  parseInt(quoteItemCopy.dataset.quoteitems)
    if ( pickupDate != null || pickupDate != undefined && returnDate != null || returnDate != undefined ){
      if ( pickupDate.value.length > 0 && returnDate.value.length > 0 ){
        const host = `${document.location.protocol}//${document.location.host}`;
        const path = '/account/quotes/current/calculate_total';
        const params = new URLSearchParams(
          { pickup_date: document.getElementById('quote_pickup_date').value, return_date: document.getElementById('quote_return_date').value }
        );
        fetch(`${host}${path}?${params}`)
                            .then(response => response.json())
                            .then(jsonResponse => this.totalValue = jsonResponse.quote_total)
        if ( (quoteItemCount - 1) > 1 ){
          this.quoteItemCopy = "Order Summary -&#160;" + (quoteItemCount - 1) + " items"
        } else {
          if ( quoteItemCount - 1 < 1 ){
            this.quoteItemCopy = "Order Summary -&#160; 0 items"
          } else {
            this.quoteItemCopy = "Order Summary -&#160;" + (quoteItemCount - 1) + " item"
          }
        }
        quoteItemCopy.dataset.quoteitems = quoteItemCount - 1
      }
    }
  }

  quoteLineItemTargetDisconnected(){
    this.updateQuotePrice();
  }

  totalValueChanged() {
    let defaultCopy = "Order Summary -&#160; "+ document.getElementById('quoteSummary').dataset.quoteitems + " items"
    this.quoteTotalTarget.setHTML(this.totalValue || "---");
    this.quoteItemCountTarget.setHTML(this.quoteItemCopy || defaultCopy);
  }
}
