import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    Array.from(document.querySelectorAll(".formtastic li")).forEach(function (e) {
      e.addEventListener("focusout", transformLabel);
      let triggerEvent = new Event("focusout");
      e.dispatchEvent(triggerEvent, transformLabel);
    });
    function transformLabel() {
      let val = this.querySelector("input, textarea");
      let label = this.querySelector("label");
      if (label !== null && val.value !== null && val.value !== "") {
        label.classList.add("transform");
      } else {
        //label.classList.remove("transform");
      }

      let autofocusEmail = this.querySelectorAll("#user_email[autofocus]");
      if (label !== null && autofocusEmail && autofocusEmail.value !== "") {
        label.classList.add("transform");
      }
    }
    if (!!document.querySelector(".formtastic textarea") == true ){
      document.querySelector(".formtastic textarea").addEventListener(
        "keydown",
        function () {
          var el = this;
          setTimeout(function () {
            el.style.cssText = "height:auto; padding:0";
            el.style.cssText = "height:" + (el.scrollHeight + 6) + "px";
          }, 0);
        },
        false
      );

    }
  }

  disconnect() {

  }


}
