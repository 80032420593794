import { Controller } from "@hotwired/stimulus"

export default class extends Controller{

  connect(){
    if (document.documentElement.clientWidth <= 992) {
      var profileLink = document.querySelectorAll(
        'li.list-group-item > a[data-controller="profile"]'
      );
      profileLink.forEach(function (e) {
        if (e.classList.contains("active")) {
          e.addEventListener("click", toggleOpen);
        }
      });

      var closeProfileBtn = document.getElementsByClassName(
        ".btn-close-acc-mobile"
      );
      Array.from(closeProfileBtn).forEach(function (e) {
        e.addEventListener("click", toggleOpen);
      });

      function toggleOpen() {
        document.addEventListener("turbo:frame-load", function () {
          document
            .querySelector("#collapseAccountDetails")
            .classList.toggle("open");
        });
      }
    }
  }
}
