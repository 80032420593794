import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    var eventTypes = ["change", "blur", "focusout"];
    eventTypes.forEach(function (e) {
      document.querySelector("#quote_quote_line_item_quantity").addEventListener(e, function () {
        var input = this;
        if (this.value !== "" || this.value.length !== 0) {
          this.previousSibling.classList.add('input-has-value')
        } else {
          this.previousSibling.classList.remove('input-has-value')
        }
      })
    });
  }
}
