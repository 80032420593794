import { Controller } from "@hotwired/stimulus"
import { Alert } from "bootstrap"

export default class extends Controller{
  connect(){
    window.bootstrap = require("bootstrap")
    var alerts = document.querySelectorAll('.alert');
    alerts.forEach(function(alert){
      var notice = new bootstrap.Alert(alert)
      alert.querySelector('.btn-close').addEventListener('click', function(){
        notice.close();
      });
    });
  }
}
