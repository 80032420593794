import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap"

export default class extends Controller {
  connect() {
    this.modal = new Modal(this.element)
    this.modal.show()

    this.element.addEventListener('turbo:submit-end', (e) => {
      if (e.detail.success) {
        // If we can figure out how to replace the login symbol and other places that would be
        // updated let's do that instead of the full reload.
        //this.close()
        window.location.reload()
      }
    })

  }

  show(){
    this.modal.show()
  }

  // I don't think we'll need this anymore?  
  hideBeforeRender(event) {
    if (this.isOpen()) {
      event.preventDefault()
      this.element.addEventListener('hidden.bs.modal', event.detail.resume)
      this.modal.hide()
    }
  }

  isOpen() {
    return this.element.classList.contains("show")
  }

  close() {
    this.modal.hide()
  }

}
