import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    let posts = document.getElementsByClassName('news-post');
    for ( let post of posts ){
      let img = post.querySelector('img');
      let url = post.dataset.url;
      img.addEventListener('click', function(){
        window.open(url, "_self");
      })
    }
    /*for ( i = 0; i < posts.length; i++){
      let el = posts[i];
      let img = el.querySelector('img');
      let url = el.dataset.url
      img.addEventListener('click', function(){
        window.open(url, 'blank')
      })
    }*/
  }
}
