import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["quotePickupDate", "quoteReturnDate", "quoteTotal"]
  static values = { total: String };

  connect(){
    const minDate = new Date().toISOString().split('T')[0];
    this.dateNow = new Date(minDate);
    this.quotePickupDateTarget.setAttribute('min', minDate);
    if ( this.quoteReturnDateTarget.value.length == 0 ){
      this.quoteReturnDateTarget.setAttribute('disabled', '');
    } else if ( this.quotePickupDateTarget.value.length > 0 && this.quoteReturnDateTarget.value.length > 0 ){
      const host = `${document.location.protocol}//${document.location.host}`;
      const path = '/account/quotes/current/calculate_total';
      const params = new URLSearchParams(
          { pickup_date: this.quotePickupDateTarget.value, return_date: this.quoteReturnDateTarget.value }
        );
      fetch(`${host}${path}?${params}`)
        .then(response => response.json())
        .then(jsonResponse => this.totalValue = jsonResponse.quote_total);
    }
  }

  dateChanged(event){
    let selectedDate = new Date(event.currentTarget.value).getTime();

    if ( selectedDate < this.dateNow.getTime() ){
      event.currentTarget.value = "";
    }

    if (this.quotePickupDateTarget.value.length > 0) {
      this.quoteReturnDateTarget.removeAttribute('disabled');
      this.quoteReturnDateTarget.setAttribute('min', this.quotePickupDateTarget.value);

      if (this.quoteReturnDateTarget.value.length > 0) {
        // Lets call an api
        const host = `${document.location.protocol}//${document.location.host}`;
        const path = '/account/quotes/current/calculate_total';
        const params = new URLSearchParams(
          { pickup_date: this.quotePickupDateTarget.value, return_date: this.quoteReturnDateTarget.value }
        );
        fetch(`${host}${path}?${params}`)
          .then(response => response.json())
          .then(jsonResponse => this.totalValue = jsonResponse.quote_total);
      }
    }
  }

  totalValueChanged() {
    this.quoteTotalTarget.setHTML(this.totalValue || "---");
  }

  showPickupCalendar() {
    this.quotePickupDateTarget.showPicker();
  }

  showReturnCalendar(){
    this.quoteReturnDateTarget.showPicker();
  }
}
