import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    var root = this;
    setTimeout(function(){
      root.windowWidth();
    }, 300)
  }

  clickTriggers(){
    var root = this;
    var footerLinks = document.querySelectorAll('#footerCollapseRent li a');
    footerLinks.forEach(function(element){
      element.addEventListener('click', function(){
        root.desktopScroll();
        if (window.innerWidth < 993 ){
          root.mobileNavTrigger();
        }
      })
    })
  }

  desktopScroll(){
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  mobileNavTrigger(){
    document.getElementById('navDropdown').click();
  }

  windowWidth(){
    var root = this;
    root.clickTriggers();
    window.addEventListener("resize", function(event){
      root.clickTriggers();
    })
  }
}
